export const config = {
  "label": "prod",
  "ModuleFE_Thunderstorm": {
    "appName": "app-advisor-frontend - (prod)"
  },
  "ModuleFE_XHR": {
    "origin": "https://advisor-backend.quai.md/",
    "timeout": 30000,
    "compress": false,
    "minLogLevel": false
  },
  "ModuleFE_FirebaseListener": {
    "firebaseConfig": {
      "apiKey": "AIzaSyCv7C4ozKjNXVgL0u8IpHv9gg9BRBPtcmk",
      "authDomain": "quai-md-runtime.firebaseapp.com",
      "databaseURL": "https://quai-md-runtime-default-rtdb.firebaseio.com",
      "projectId": "quai-md-runtime",
      "storageBucket": "quai-md-runtime.appspot.com",
      "messagingSenderId": "662263362818",
      "appId": "1:662263362818:web:c4392723622cb020f17e1b"
    }
  }
};